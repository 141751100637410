.available {
    background-color: #cce5ff;
}

.unavailable {
    background-color: #ffc107;
}



/*.btn:focus {*/
/*    background-color: #ffffff;*/
/*}*/

/*.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus {*/
/*    box-shadow: 0 0 0 0.2rem #ffffff;*/
/*}*/


/*.btn-default:active, .btn-default:focus {*/
/*    color: #333;*/
/*    background-color: #fff;*/
/*    border-color: #ccc;*/
/*    outline: 0;*/
/*}*/
/*.btn-outline-primary.dropdown-toggle:focus {*/
/*    outline: none !important;*/
/*    outline-offset: 0rem !important;*/
/*}*/

/*.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus {*/
/*    box-shadow: none !important;*/
/*}*/

/*.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus {*/
/*    box-shadow: none !important;*/
/*}*/

/*.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {*/
/*    background-color: #ffffff;*/
/*}*/



/*.btn-outline-primary:hover {*/
/*    */
/*}*/


