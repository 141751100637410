@media (max-width: 768px) {
    .desktop-only {
        visibility: hidden;
        clear: both;
        float: left;
        margin: 10px auto 5px 20px;
        width: 10%;
        display: none;
    }
}

@media (min-width: 768px) {
    .desktop-only {
        width: 10%;
    }
}

