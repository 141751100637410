
.help-symbol {
    color:#fff;
    background-color:#feb22a;
    width:12px;
    height:12px;
    margin-right: .25rem;
    display:inline-block;
    border-radius:100%;
    font-size:10px;
    text-align:center;
    text-decoration:none;
    -webkit-box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
    box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
}