@media (max-width: 768px) {
    .desktop-row-only {
        visibility: hidden;
        display: none;
    }
}

@media (min-width: 768px) {
    .desktop-row-only {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
    }
}

@media (min-width: 768px) {
    .mobile-row-only {
        visibility: hidden;
        display: none;
    }
}

@media (max-width: 768px) {
    .mobile-row-only {
        display: flex;
    }
}



.red-icon {
    position: absolute;
    top: 50px;
    right: 20px;
    background: #2bb2ff;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 100%;
    z-index: 2000;
    font-size: 6rem;
}

.green-icon {
    position: absolute;
    top: 50px;
    left: 20px;
    background: green;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 100%;
    z-index: 2000;
    font-size: 6rem;
}
