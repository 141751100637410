.tier-selected {
    display: table-cell !important;
}

.item-included:before {
    content: '✔';
    color: green;
}

.item-not-included:before {
    content: '❌';
}

.desktop-head {
    text-align: center;
}

tbody {
    text-align: center;
}

.th-switch-cell {
    width: 44%
}

.feature-item-name {
    text-align: left;
}

.item-included-text {

}


@media (max-width: 768px) {
    .desktop-head {
        display: none;
    }
    .mobile-head {
        display: table-row;
    }

    .basic-tier {
        display: none
    }

    .pro-tier {
        display: none
    }

    .coach-tier {
        display: none
    }
}

@media (min-width: 768px) {
    .desktop-head {
        display: table-row;
    }
    .mobile-head {
        display: none;
    }
    .basic-tier {
        display: table-cell
    }

    .pro-tier {
        display: table-cell
    }

    .coach-tier {
        display: table-cell
    }
}



.pricing-table-monthly .tier-price-monthly {
    display: block;
}

.pricing-table-monthly .tier-price-annually {
    display: none;
}

.pricing-table-annually .tier-price-annually {
    display: block;
}

.pricing-table-annually .tier-price-monthly {
    display: none;
}





.menuItem {
    display:block;
    width:100%;
    height:20px;
    background:#ff0;
}

.subItem {
    display: none;
}

.cb {
    display: none;
}

.custom-control-input:checked ~ .subItem {
    display: block;
}


.custom-control-input:not(:checked) ~ .price-monthly {
    display: block;
}

.custom-control-input:checked ~ .pricing-table-head ~ .price-monthly {
    display: none;
}

