.privacy-background {
    background-color: #e4edff;
}

.privacy-no-display {
    display: none;
}

.normal-display-flex {
    display: flex;
}


@media (max-width: 768px) {
    .smaller-mobile-font {
        font-size: 1.25rem !important;
    }
}

@media (max-width: 768px) {
    .small-mobile-button {
        font-size: 0.75rem !important;
    }
}

.tooltip div {
    text-align: left;
}

.btn-xlarge {
    font-size: 2rem;
    height: 10rem;
    width: 10rem;
    line-height: normal;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}

pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
